import React, { Component } from 'react';
import { Button, Spinner, Container, Row, Col } from 'react-bootstrap';
import { ethers } from "ethers";
import { isMobile } from 'react-device-detect';
import { patp } from "urbit-ob";
import { sigil, reactRenderer } from '@tlon/sigil-js';
import './Tiles.css';

interface Props {
  treasuryOwnedPoints: number[];
  liquidityDepth: number;
  baseQuote: ethers.BigNumber;
  ethQuote: ethers.BigNumber;
  buy:((idx: number) => void);
  updatePriceQuoteForTile:((depth: number) => void);
  selectedStar: number;
  buyButtonClicked: boolean;
}

interface State {
  blurTileIdx: number;
  priceQuoteCurrent: boolean;
}

class Tiles extends Component<Props, State> {

  public state = {
    blurTileIdx: -1,
    priceQuoteCurrent: false
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.ethQuote !== this.props.ethQuote) {
      this.setState({ priceQuoteCurrent: true });
    }
  }

  private buildRow = (startIdx: number) => {
    const { baseQuote, ethQuote, treasuryOwnedPoints, liquidityDepth, buyButtonClicked, selectedStar, updatePriceQuoteForTile } = this.props;
    const { blurTileIdx } = this.state;
    const buyButton = (patp: string, idx: number) => {
      return (
        <Container className={ isMobile ? 'buy-button-wrapper-mobile' : 'buy-button-wrapper' }>
          <Button
            variant="dark"
            size="lg"
            onClick={() => this.props.buy(idx)}
            disabled={buyButtonClicked && (selectedStar === idx)}
            className={ isMobile ? 'buy-button-mobile' : 'buy-button' }
          >
            <Container className={ isMobile ? 'buy-button-label-wrapper-mobile' : 'buy-button-label-wrapper' }>
              <Spinner
                as="span"
                size="sm"
                animation="border"
                role="status"
                aria-hidden="true"
                hidden={!buyButtonClicked}
                style={{ marginRight: '10px' }}
              />
              <p className={ isMobile ? 'buy-button-label-mobile' : 'buy-button-label' }>
                {buyButtonClicked ? 'Loading...' : `Buy ${patp}`}
              </p>
            </Container>
          </Button>
        </Container>
      )
    };
    const basePrice = Number(ethers.utils.formatEther(baseQuote).slice(0, 12));
    const basePriceDiff = (basePrice / 101) * 1.025;  // basePrice is quoted for 1.01 WSTR, so basePriceDiff is calc'd as price of 0.01 WSTR * 1.025 modifier
    const tile = (idx: number) => {
      const starName = patp(treasuryOwnedPoints[idx].toString());
      const listPrice = basePrice + (basePriceDiff * idx);
      const listPriceLabel = `~${listPrice.toString().slice(0, 4)} ETH`;
      const quotedPriceLabel = `${ethers.utils.formatEther(ethQuote).slice(0, 7)} ETH`;
      const sigilWidth = isMobile ? 160 : 300;
      return (
        <div
          onMouseEnter={() => this.setState({ blurTileIdx: idx, priceQuoteCurrent: false }, () => updatePriceQuoteForTile(idx))}
          onMouseLeave={() => this.setState({ blurTileIdx: -1 })}
          className={ isMobile ? 'tile-wrapper-mobile' : 'tile-wrapper' }
        >
          {this.sigil(starName, sigilWidth)}
          {idx === blurTileIdx
            ? buyButton(starName, idx)
            : null}
          <Row>
            <Col style={{ display: 'flex', paddingTop: '12px', paddingBottom: '12px' }}>
              <p className={ isMobile ? 'tile-star-name-label-mobile' : 'tile-star-name-label' }>
                <strong>{`${starName}`}</strong>
              </p>
            </Col>
            <Col className={ isMobile ? 'tile-star-price-label-wrapper-mobile' : 'tile-star-price-label-wrapper' }>
              <p className={ isMobile ? 'tile-star-price-label-mobile' : 'tile-star-price-label' }>
                <strong>
                  {idx === blurTileIdx
                    ? this.state.priceQuoteCurrent
                      ? quotedPriceLabel
                      : <Spinner
                          as="span"
                          size="sm"
                          animation="border"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: '10px' }}
                        />
                    : listPriceLabel}
                </strong>
              </p>
            </Col>
          </Row>
        </div>
      )
    };
    const tilesPerRow = isMobile ? 2 : 4;
    return (
      <Row key={startIdx.toString()}>
        {[...Array(tilesPerRow)].map((item, idx) => 
          (startIdx + idx) < liquidityDepth
            ? <Col key={idx.toString()}>{tile(startIdx + idx)}</Col>
            : null
        )}
      </Row>
    )
  }

  private gridDisplay = () => {
    const { treasuryOwnedPoints, liquidityDepth } = this.props;
    const tilesPerRow = isMobile ? 2 : 4;
    const rows = treasuryOwnedPoints.slice(0, liquidityDepth)
      .filter((ownedPoint, idx) => 
        idx % tilesPerRow === 0)
      .map(ownedPoint => 
        this.buildRow(treasuryOwnedPoints.indexOf(ownedPoint)));
    return (
      <Container>{rows}</Container>
    );
  }

  private sigil = (patp: string, size: number) => {
    const borderRadius = isMobile ? '15px' : '25px';
    return (
      <>
        {
          sigil({
            patp: patp,
            renderer: reactRenderer,
            size: size,
            colors: ['black', 'white'],
            style: { 
              borderTopLeftRadius: borderRadius,
              borderTopRightRadius: borderRadius
            }
          })
        }
      </>
    )
  }

  render() {
    return (
      <div className="Tiles">
        {this.props.treasuryOwnedPoints.length > 0
          ? this.gridDisplay()
          : null}
      </div>
    );
  }
}

export default Tiles;