import { ethers } from "ethers";
import { 
  azimuthAddress, 
  eclipticAddress, 
  wstrAddress, 
  treasuryAddress, 
  poolAddress, 
  wethAddress, 
  wstrFlashSwapAddress, 
  quoterAddress 
} from './Addresses';
import AzimuthABI from './abi/AzimuthABI.json';
import WstrABI from './abi/wstrABI.json';
import WstrFlashSwapABI from './abi/wstrFlashSwapABI.json';
import EclipticABI from './abi/EclipticABI.json';

const flash = (
  provider: ethers.providers.Web3Provider,
  idx: number,
  targetStar: number, 
  wstrAmountOut: ethers.BigNumber, 
  ethAmount: ethers.BigNumber
): Promise<ethers.providers.TransactionResponse> => { 
  return new Promise((resolve, reject) => {

      const wstrFlashSwapContract = new ethers.Contract(
        wstrFlashSwapAddress, 
        WstrFlashSwapABI, 
        provider.getSigner()
      );

      wstrFlashSwapContract.initFlashWithSwap({
        token0: ethers.utils.getAddress(wethAddress),
        token1: ethers.utils.getAddress(wstrAddress),
        fee1: 10000,  // 1%
        amount0: 0,  // WETH amount
        amount1: ethers.utils.parseUnits(idx.toString(), 18), // WSTR amount
        fee2: 500,
        fee3: 500,
        targetStar: targetStar,
        depth: idx + 1,
        wstrAmountOut: wstrAmountOut
      },  {
        value: ethAmount
      }).then((txResponse: ethers.providers.TransactionResponse) => resolve(txResponse))
        .catch((error: Error) => reject(error));

  });
}

const approveTransfer = (
  provider: ethers.providers.Web3Provider,
  star: number
): Promise<ethers.providers.TransactionResponse> => { 
  return new Promise((resolve, reject) => {

    const eclipticContract = new ethers.Contract(
      eclipticAddress, 
      EclipticABI, 
      provider.getSigner()
    );

    eclipticContract.approve(
      wstrFlashSwapAddress,
      star
    ).then((txResponse: ethers.providers.TransactionResponse) => resolve(txResponse))
     .catch((error: Error) => reject(error));

  });
}

const sellStar = (
  provider: ethers.providers.Web3Provider,
  star: number, 
  amountOutMinimum: ethers.BigNumber
): Promise<ethers.providers.TransactionResponse> => { 
  return new Promise((resolve, reject) => {

    const wstrFlashSwapContract = new ethers.Contract(
      wstrFlashSwapAddress, 
      WstrFlashSwapABI, 
      provider.getSigner()
    );

    wstrFlashSwapContract.initSwapStarForETH(
      star,
      amountOutMinimum
    ).then((txResponse: ethers.providers.TransactionResponse) => resolve(txResponse))
     .catch((error: Error) => reject(error));

  });
}

const retrieveApprovalStatus = (
  provider: ethers.providers.JsonRpcProvider,
  star: number
): Promise<string> => {
  return new Promise((resolve, reject) => {

    const ecliptic = new ethers.Contract(
      eclipticAddress,
      EclipticABI,
      provider
    );

    ecliptic.getApproved(star)
      .then((approvedAddress: string) => resolve(approvedAddress))
      .catch((error: Error) => reject(error));

  });
}

const retrieveUserOwnedStars = (
  provider: ethers.providers.BaseProvider,
  address: string
): Promise<number[]> => {
  return new Promise((resolve, reject) => {

    const azimuth = new ethers.Contract(
      azimuthAddress,
      AzimuthABI,
      provider
    );

    azimuth.getOwnedPoints(address)
      .then((userOwnedPoints: Array<number>) => resolve(userOwnedPoints))
      .catch((error: Error) => reject(error));

  });
}

const retrieveTreasuryOwnedPoints = (provider: ethers.providers.BaseProvider, maxDepth: number): Promise<number[]> => {
    return new Promise((resolve, reject) => {

      const azimuth = new ethers.Contract(
        azimuthAddress,
        AzimuthABI,
        provider
      );

      azimuth.getOwnedPoints(treasuryAddress)
        // Initial slice to create a copy
        .then((treasuryOwnedPoints: Array<number>) => resolve(treasuryOwnedPoints.slice().reverse().slice(0, maxDepth)))
        .catch((error: Error) => reject(error));

    });
  }

const retrieveLiquidityDepthOfPool = (provider: ethers.providers.BaseProvider): Promise<number> => {
    return new Promise((resolve, reject) => {

      const wstr = new ethers.Contract(
        wstrAddress,
        WstrABI,
        provider
      );

      wstr.balanceOf(poolAddress)
        .then((poolBalance: ethers.BigNumber) => {
          const liquidityDepth = Math.round(parseFloat(ethers.utils.formatEther(poolBalance))) - 1;
          resolve(liquidityDepth);
        }).catch((error: Error) => reject(error));

    });
}

const retrieveUserETHBalance = (
  provider: ethers.providers.BaseProvider,
  address: string
): Promise<ethers.BigNumber> => {
    return new Promise((resolve, reject) => {

      provider.getBalance(address)
        .then((balance: ethers.BigNumber) => resolve(balance))
        .catch((error: Error) => reject(error));

    });
}

const retrieveBaseQuote = (provider: ethers.providers.BaseProvider): Promise<ethers.BigNumber> => {
  return new Promise((resolve, reject) => {

    retrieveWSTRPriceQuote(
      provider,
      0
    ).then(priceQuote => resolve(priceQuote))
     .catch((error: Error) => reject(error));

  });
}

const retrievePriceQuoteForTile = (
  provider: ethers.providers.BaseProvider,
  depth: number
): Promise<ethers.BigNumber> => {
  return new Promise((resolve, reject) => {

      retrieveWSTRPriceQuote(
        provider,
        depth
      ).then(priceQuote => resolve(priceQuote))
       .catch((error: Error) => reject(error));

  });
}

const retrieveETHPriceQuoteForPortfolio = (provider: ethers.providers.BaseProvider): Promise<ethers.BigNumber> => {
  return new Promise((resolve, reject) => {

    retrieveETHPriceQuote(provider)
      .then(priceQuote => resolve(priceQuote))
      .catch((error: Error) => reject(error));

  });
}

const retrieveWSTRPriceQuote = (
  provider: ethers.providers.BaseProvider,
  depth: number
): Promise<ethers.BigNumber> => {
  return new Promise((resolve, reject) => {

      const quoter = new ethers.Contract(
        quoterAddress,
        [ "function quoteExactOutputSingle(address tokenIn, address tokenOut, uint24 fee, uint256 amountOut, uint160 sqrtPriceLimitX96) external view returns (uint256 amountIn)" ],
        provider
      );

      const wstrAmountOut = ethers.utils.parseUnits('1', 18).add(ethers.utils.parseUnits(depth.toString(), 16));

      quoter.quoteExactOutputSingle(
        wethAddress, 
        wstrAddress, 
        10000, 
        wstrAmountOut, 
        0
      ).then((priceQuote: ethers.BigNumber) => resolve(priceQuote))
       .catch((error: Error) => reject(error));

  });
}

const retrieveETHPriceQuote = (provider: ethers.providers.BaseProvider): Promise<ethers.BigNumber> => {
  return new Promise((resolve, reject) => {

      const quoter = new ethers.Contract(
        quoterAddress,
        [ "function quoteExactInputSingle(address tokenIn, address tokenOut, uint24 fee, uint256 amountIn, uint160 sqrtPriceLimitX96) external view returns (uint256 amountOut)" ],
        provider
      );

      const wstrAmountIn = ethers.utils.parseUnits('1', 18);

      quoter.quoteExactInputSingle(
        wstrAddress, 
        wethAddress, 
        10000, 
        wstrAmountIn, 
        0
      ).then((priceQuote: ethers.BigNumber) => resolve(priceQuote))
       .catch((error: Error) => reject(error));

  });
}

export { flash, approveTransfer, sellStar, retrieveApprovalStatus, retrieveUserOwnedStars, retrieveTreasuryOwnedPoints, retrieveLiquidityDepthOfPool, retrieveETHPriceQuote, retrieveUserETHBalance, retrieveBaseQuote, retrievePriceQuoteForTile, retrieveETHPriceQuoteForPortfolio }