import React, { Component } from 'react';
import { Button, Container, Row, Col, Modal, Badge, Accordion, Spinner, Alert } from 'react-bootstrap';
import { ethers } from "ethers";
import { patp } from "urbit-ob";
import { ToastContainer, toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { sigil, reactRenderer } from '@tlon/sigil-js';
import Tiles from './components/Tiles';
import {
  flash, 
  approveTransfer, 
  sellStar, 
  retrieveApprovalStatus,
  retrieveUserOwnedStars,
  retrieveTreasuryOwnedPoints,
  retrieveLiquidityDepthOfPool,
  retrieveUserETHBalance, 
  retrieveBaseQuote, 
  retrievePriceQuoteForTile, 
  retrieveETHPriceQuoteForPortfolio 
} from './OnChain';
import { wstrFlashSwapAddress } from './Addresses';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

interface State {
  provider: ethers.providers.BaseProvider;
  ethAccounts: string[];
  treasuryOwnedPoints: number[];
  userOwnedStars: number[];
  userETHBalance: ethers.BigNumber;
  liquidityDepth: number;
  baseQuote: ethers.BigNumber;
  ethAmountOutQuote: ethers.BigNumber;
  ethAmountInQuote: ethers.BigNumber;
  selectedStar: number;
  buyButtonClicked: boolean;
  sellButtonClicked: boolean;
  walletButtonClicked: boolean;
  showAlert: boolean;
}

export const ERROR_NO_WALLET_DETECTED = "ERROR_NO_WALLET_DETECTED"
export const MAX_TREASURY_DEPTH = 40

class App extends Component<any, State> {

  public state = {
    provider: Object() as ethers.providers.BaseProvider,
    ethAccounts: Array<string>(),
    treasuryOwnedPoints: Array<number>(),
    userOwnedStars: Array<number>(),
    userETHBalance: ethers.BigNumber.from('0'),
    liquidityDepth: 0,
    baseQuote: ethers.BigNumber.from('0'),
    ethAmountOutQuote: ethers.BigNumber.from('0'),
    ethAmountInQuote: ethers.BigNumber.from('0'),
    selectedStar: -1,
    buyButtonClicked: false,
    sellButtonClicked: false,
    walletButtonClicked: false,
    showAlert: true
  }

  componentDidMount() {
    this.setupProvider();
  }

  render() {
    const { treasuryOwnedPoints, liquidityDepth, baseQuote, ethAmountOutQuote, selectedStar, buyButtonClicked, showAlert } = this.state;
    const headerBrand = 
      <Col>
        <h1 className='brand'>Star Swap</h1>
        <h3 className='tagline'>one-click Urbit{'\u2194'}ETH swaps</h3>
      </Col>;
    const walletButton = 
      <Col xs lg="4">
        {this.buildWalletButton()}
      </Col>;
    const header = isMobile
      ? <Row>
          {walletButton}
          {headerBrand}
        </Row>
      : <Row>
          {headerBrand}
          {walletButton}
        </Row>;
    return (
      <div className="App">
        <header className="App-header">
        <Container>
          {header}
          {this.buildModal()}
        </Container>
        {showAlert
          ? this.buildAlert()
          : null}
        </header>
        <ToastContainer/>
        <Tiles 
          treasuryOwnedPoints={treasuryOwnedPoints}
          liquidityDepth={liquidityDepth}
          baseQuote={baseQuote}
          ethQuote={ethAmountOutQuote}
          buy={this.buy}
          updatePriceQuoteForTile={this.updatePriceQuoteForTile}
          selectedStar={selectedStar}
          buyButtonClicked={buyButtonClicked}
        />
      </div>
    );
  }

  private setupProvider = () => {
    const providerUrl = process.env.NODE_ENV === 'development'
      ? "http://localhost:8545"
      : "https://eth-mainnet.alchemyapi.io/v2/" + process.env.REACT_APP_ALCHEMY_MAINNET_KEY;
    this.setState({ 
      provider: new ethers.providers.JsonRpcProvider(providerUrl)
    }, () => {
      this.updateTreasuryOwnedPoints();
      this.updateLiquidityDepth();
      this.updateBaseQuote();
    });
  }

  private buildWalletButton = () => {
    const { ethAccounts, userOwnedStars, userETHBalance } = this.state;
    const starCount = userOwnedStars.length;
    const button = ethAccounts.length > 0
      ? <Button 
          variant="outline-dark"
          size="lg"
          onClick={() => this.openModal()}
          className='wallet-button'
        >
          <div style={{ display: 'flex' }}>
            <p className={ isMobile ? 'wallet-button-address-mobile' : 'wallet-button-address' }>{this.buildWalletAddressLabel()}</p>
            <Badge bg="warning" className={ isMobile ? 'eth-balance-badge-mobile' : 'eth-balance-badge' }>{`${ethers.utils.formatEther(userETHBalance).slice(0, 7)} ETH `}</Badge>
            <Badge bg="warning" className={ isMobile ? 'star-balance-badge-mobile' : 'star-balance-badge' }>{`${starCount} star${starCount === 1 ? '' : 's'}`}</Badge>
          </div>
        </Button>
      : <Button 
          variant="outline-dark"
          size="lg"
          onClick={() => this.connectWalletButtonClicked(true)}
          className={ isMobile ? 'wallet-button-mobile' : 'wallet-button' }
          style={{ paddingTop: '8px' }}
        >
          <p className={ isMobile ? 'wallet-button-connect-mobile' : 'wallet-button-connect' }>Connect wallet</p>
        </Button>;
    return (
      <Container>
        {button}
      </Container>
    )
  }

  private openModal = () => {
    this.setState({ walletButtonClicked: true });
  }

  private closeModal = () => {
    this.setState({ walletButtonClicked: false });
  }

  private buildModal = () => {
    const { userOwnedStars, ethAmountInQuote, walletButtonClicked, ethAccounts, sellButtonClicked } = this.state;
    const currentPriceLabel = `${ethers.utils.formatEther(ethAmountInQuote).slice(0, 5)} ETH`;
    const portfolioRows = userOwnedStars.map((star, idx) => 
      <Accordion.Item 
        eventKey={idx.toString()}
        key={idx.toString()}
      >
        <Accordion.Header>
          {this.sigil(patp(star.toString()), 75)}
          <p className='modal-row-header'>{patp(star.toString())}</p>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Button 
              variant="outline-dark" 
              onClick={() => this.sell(star)}
              style={{ fontSize: '25px' }}
              disabled={sellButtonClicked}
            >
              <Container>
                <Spinner
                  as="span"
                  size="sm"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  hidden={!sellButtonClicked}
                  style={{ marginRight: '10px' }}
                />
                <strong>{sellButtonClicked ? 'Loading...' : `Sell ${patp(star.toString())} for ${currentPriceLabel}`}</strong>
              </Container>
            </Button>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    );
    const modalTitle = ethAccounts.length > 0
      ? this.buildWalletAddressLabel()
      : '';
    const modalDesc = userOwnedStars.length > 0
      ? 'Select a star to sell'
      : 'No stars to sell';
    return (
      <Modal show={walletButtonClicked} onHide={this.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className='modal-title'>{modalTitle}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='modal-desc'>{modalDesc}</p>
          <Accordion flush>
            {portfolioRows}
          </Accordion>
        </Modal.Body>
      </Modal>
    )
  }

  private buildAlert = () => {
    return (
      <Container>
        <Alert 
          variant="warning" 
          onClose={() => this.setState({ showAlert: false })} 
          style={{ fontSize: '14px', paddingTop: '5px' }}
          dismissible
        >
          <Alert.Heading style={{ marginTop: '5px', marginBottom: '5px' }}>
            <div style={{ display: 'inline-flex' }}>
              <p style={{ fontSize: '15px', marginBottom: '0px', paddingTop: '2px' }}>
                Star Swap is unaudited beta software. Use at your own risk.
              </p>
            </div>
          </Alert.Heading>
        </Alert>
      </Container>
    )
  }

  private sigil = (patp: string, size: number) => {
    return (
      <>
        {
          sigil({
            patp: patp,
            renderer: reactRenderer,
            size: size,
            colors: ['black', 'white'],
            style: { borderRadius: '10px' }
          })
        }
      </>
    )
  }

  private buildWalletAddressLabel = () => {
    const { ethAccounts } = this.state;
    const address = ethAccounts[0];
    const end = address.length - 1;
    return address.slice(0, 6) + '...' + address.slice(end - 4, end);
  }

  private connectWalletButtonClicked = (showNoWalletDetectedError: boolean) => {

    this.connectWeb3()
      .then((accounts: string[]) => {

        if (accounts.length > 0) {
          this.setState({ ethAccounts: accounts }, () => {
            this.updateUserOwnedStars();
            this.updateUserETHBalance();
          });
        }
        
      }).catch((error: Error) => {
        if (error.message == ERROR_NO_WALLET_DETECTED && showNoWalletDetectedError) {
          this.emitErrorToast('No wallet detected');
        } else if (error.message != ERROR_NO_WALLET_DETECTED) {
          this.emitErrorToast(error.message)
        }
      });
  }

  private connectWeb3 = (): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      const wndw = (window as any);
      const ethereum = wndw.ethereum;

      if (ethereum != undefined) {
        ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((accounts: string[]) => resolve(accounts))
          .catch((error: any) => {
            if (error.code === 4001) {
              // EIP-1193 userRejectedRequest error
              reject('Please connect to MetaMask.');
            } else {
              reject(error);
            }
          });
        ethereum.on('accountsChanged', (accounts: string[]) => {
          this.setState({ ethAccounts: accounts }, () => {
            if (accounts.length > 0) {
              this.updateUserOwnedStars();
              this.updateUserETHBalance();
            }
          });
        });
      } else {
        reject(Error(ERROR_NO_WALLET_DETECTED));
      }
    });
  }

  private buy = (idx: number) => {
    const { treasuryOwnedPoints, ethAmountOutQuote } = this.state;

    this.connectWeb3()
      .then(() => {

        this.setState({
          buyButtonClicked: true,
          selectedStar: idx
        });

        const wndw = (window as any);
        const web3Provider = new ethers.providers.Web3Provider(wndw.ethereum);
        const targetStar = treasuryOwnedPoints[idx];

        const wstrForFee = ethers.utils.parseUnits(idx.toString(), 16);
        const wstrAmountOut = ethers.utils.parseUnits('1.0', 18).add(wstrForFee);
        
        flash(
          web3Provider,
          idx,
          targetStar, 
          wstrAmountOut,
          ethAmountOutQuote
        ).then(txResponse => {
            this.emitPendingTxToast(txResponse.hash);

            txResponse.wait(1).then(() => {
              this.setState({
                buyButtonClicked: false,
                selectedStar: -1
              }, () => {
                this.emitSuccessToast(`Bought ${patp(targetStar.toString())} for ${ethers.utils.formatEther(ethAmountOutQuote).slice(0, 5)} ETH`);
                this.refreshDataAfterTx();
              });
            }).catch((error: Error) => this.emitErrorToast(error.message));

        }).catch((error: Error) => {
          this.emitErrorToast(error.message);
          console.log(error);
          this.setState({
            buyButtonClicked: false,
            selectedStar: -1
          });
        });

      }).catch((error: Error) => this.emitErrorToast(error.message));
  }

  private approve = (star: number) => {
    const wndw = (window as any);
    const web3Provider = new ethers.providers.Web3Provider(wndw.ethereum);

    approveTransfer(
      web3Provider,
      star
    ).then(txResponse => {
      this.emitPendingTxToast(txResponse.hash);

      txResponse.wait(1).then(() => {
        this.emitSuccessToast(`Approved ${patp(star.toString())} to transfer`);
        this.sell(star);
      }).catch((error: Error) => this.emitErrorToast(error.message));

    }).catch((error: Error) => {
      console.log(error);
      this.setState({ sellButtonClicked: false });
      this.emitErrorToast(error.message);
    });
  }

  private sell = (star: number) => {
    const { ethAmountInQuote } = this.state;
    const wndw = (window as any);
    const web3Provider = new ethers.providers.Web3Provider(wndw.ethereum);

    this.setState({ sellButtonClicked: true }, () => 

      retrieveApprovalStatus(web3Provider, star)
        .then((approvedAddress: string) => {
          if (approvedAddress === wstrFlashSwapAddress) {
            sellStar(
              web3Provider,
              star,
              ethAmountInQuote
            ).then(txResponse => {
                this.emitPendingTxToast(txResponse.hash);

                txResponse.wait(1).then(() => {
                  this.closeModal();
                  this.setState({ 
                    ethAmountInQuote: ethers.BigNumber.from('0'),
                    sellButtonClicked: false 
                  }, () => {
                    this.emitSuccessToast(`Sold ${patp(star.toString())} for ${ethers.utils.formatEther(ethAmountInQuote).slice(0, 5)} ETH`);
                    this.refreshDataAfterTx();
                  })
                }).catch((error: Error) => this.emitErrorToast(error.message));

              }).catch((error: Error) => {
                console.log(error);
                this.emitErrorToast(error.message);
              });          
          } else {
            this.approve(star);
          }
        }).catch((error: Error) => console.log(error))
    )
  }

  private refreshDataAfterTx = () => {
    this.updateBaseQuote();
    this.updateUserOwnedStars();
    this.updateUserETHBalance();
    this.updateTreasuryOwnedPoints();
    this.updateLiquidityDepth();
  }

  private updateBaseQuote = () => {
    retrieveBaseQuote(this.state.provider)
      .then(baseQuote => this.setState({ baseQuote }))
      .catch(error => console.log(error));
  }

  private updateUserOwnedStars = () => {
    const { provider, ethAccounts } = this.state;
    retrieveUserOwnedStars(
      provider,
      ethAccounts[0]
    ).then(userOwnedPoints => {

      this.setState({ userOwnedStars: userOwnedPoints.filter(point => (point < 65536) && (point > 255)) }, () => {
        if (this.state.userOwnedStars.length > 0) {
          retrieveETHPriceQuoteForPortfolio(provider)
            .then(ethAmountInQuote => this.setState({ ethAmountInQuote }))
            .catch(error => console.log(error));
        }
      });

    }).catch(error => console.log(error));
  }

  private updateUserETHBalance = () => {
    const { provider, ethAccounts } = this.state;
    retrieveUserETHBalance(
      provider,
      ethAccounts[0]
    ).then(userETHBalance => this.setState({ userETHBalance }))
     .catch(error => console.log(error));
  }

  private updateTreasuryOwnedPoints = () => {
    retrieveTreasuryOwnedPoints(this.state.provider, MAX_TREASURY_DEPTH)
      .then(treasuryOwnedPoints => this.setState({ treasuryOwnedPoints: [...treasuryOwnedPoints] }))
      .catch(error => console.log(error));
  }

  private updateLiquidityDepth = () => {
    retrieveLiquidityDepthOfPool(this.state.provider)
      .then(liquidityDepth => this.setState({ liquidityDepth }))
      .catch(error => console.log(error));
  }

  private updatePriceQuoteForTile = (depth: number) => {
    retrievePriceQuoteForTile(this.state.provider, depth)
      .then(priceQuote => 
        this.setState({ 
          ethAmountOutQuote: priceQuote, 
          selectedStar: depth 
        })
      ).catch(error => console.log(error));
  }

  private emitPendingTxToast = (txHash: string) => {
    const toastContent = 
      <a 
        href={'https://etherscan.io/tx/' + txHash} 
        target='_blank' 
        rel='noreferrer'
        style={{ textDecoration: 'none' }}
      >
        Transaction pending
        <i className='fas fa-external-link-alt link-icon'></i>
      </a>;
    toast.info(toastContent, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  private emitSuccessToast = (message: string) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  private emitErrorToast = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  }
}

export default App;